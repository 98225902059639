import React from "react";

const FilePreview = ({ fileUrl }) => {
  // Function to check file type
  const isImage = (url) => /\.(jpeg|jpg|gif|png)$/.test(url);
  const isPDF = (url) => /\.pdf$/.test(url);
  const isDoc = (url) => /\.(doc|docx)$/.test(url);
  const isText = (url) => /\.txt$/.test(url);
  const isVideo = (url) => /\.(mp4|webm|ogg)$/.test(url);

  return (
    <div style={{ width: "100%", height: "500px", border: "1px solid #ddd" }}>
      {isImage(fileUrl) && (
        <img
          src={fileUrl}
          alt="File"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
      {isPDF(fileUrl) && (
        <iframe
          src={fileUrl}
          style={{ width: "100%", height: "100%" }}
          title="PDF Preview"
        />
      )}
      {isDoc(fileUrl) && (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            fileUrl
          )}`}
          style={{ width: "100%", height: "100%" }}
          title="DOC Preview"
        />
      )}
      {isText(fileUrl) && (
        <iframe
          src={fileUrl}
          style={{ width: "100%", height: "100%" }}
          title="Text Preview"
        />
      )}
      {isVideo(fileUrl) && (
        <video controls style={{ width: "100%", height: "100%" }}>
          <source src={fileUrl} type="video/mp4" />
          <source src={fileUrl} type="video/webm" />
          <source src={fileUrl} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
      {!isImage(fileUrl) &&
        !isPDF(fileUrl) &&
        !isDoc(fileUrl) &&
        !isText(fileUrl) &&
        !isVideo(fileUrl) && <p>Unsupported file format</p>}
    </div>
  );
};

export default FilePreview;

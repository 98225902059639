import React, { useEffect } from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import { useDispatch, useSelector } from "react-redux";
import { getLastFormation } from "../redux/actions/formationActions";
import Loader from "../components/Loader";
import Alert from "../components/Alert";

function PricingScreen() {
  const dispatch = useDispatch();

  const formationLast = useSelector((state) => state.lastFormation);
  const {
    loadingLastFormation,
    errorLastFormation,
    successLastFormation,
    formation,
  } = formationLast;

  useEffect(() => {
    dispatch(getLastFormation());
  }, [dispatch]);

  return (
    <div className="text-black bg-white min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Pricing
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto my-10">
        <div className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/2 w-full px-2">
            <div>
              Online learning, also referred to as remote or distance learning,
              is a form of home-based education that allows individuals to
              participate at their convenience. This mode of learning is
              adaptable, easily accessible, and aims to empower learners to
              enhance their career prospects and broaden their knowledge base.
              Regardless of your circumstances or location in the world, online
              learning provides flexibility, enabling you to overcome potential
              barriers such as full or part-time employment, disabilities, or
              caregiving responsibilities. With the opportunity to unlock your
              potential, pursue a respected, industry-recognized qualification
              through distance learning with Hijama Online Academy.
            </div>
          </div>
          <div className="md:w-1/2 w-full px-2">
            <div>
              <img src="https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRm9ubGluZS1sZWFybmluZy5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyNTAyOS0zMjc3NiZ0b2tlbj1hNWFlMTQ0NGNmYWQ5ZGY0.q.jpg" />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="text-center font-bold text-[#1259ae] text-2xl mt-10">
          Study for a Certified Diploma with Hijama Online Academy
        </div>
        <div className="text-center font-bold text-2xl my-5">
          Initiate Your Application
        </div>
        {/*  */}
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 1</div>
              </div>
              <div className="text-center">
                Complete the application form / Make the payment for the course
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 2</div>
              </div>
              <div className="text-center">
                Receive, login details and commence your Online Learning Journey
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 3</div>
              </div>
              <div className="text-center">
                Gain your Diploma qualification, & start treating patients in
                your new clinic
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="bg-[#FFFAFA] w-full h-full bg-opacity-80">
            <div className="container mx-auto py-5 ">
              <div className="flex md:flex-row flex-col">
                <div className="md:w-2/3 w-full px-2 mx-auto">
                  {loadingLastFormation ? (
                    <Loader />
                  ) : errorLastFormation ? (
                    <Alert type={"error"} message={errorLastFormation} />
                  ) : formation ? (
                    <div className="px-2 py-3 md:w-[80%] w-full mx-auto">
                      <div className="bg-[#1259ae] px-3 py-3 text-center relative">
                        <div className="text-white text-xl">
                          {formation.title_formation ?? ""}
                        </div>
                        <div className="text-white text-xs">
                          {formation.shortdescription_formation}
                        </div>
                        <div className="  absolute top-0 right-0 overflow-hidden w-[150px] h-[150px] rotate-90 ">
                          <div
                            className="bg-black text-white w-[200%] text-center text-[10px] uppercase font-bold mr-4"
                            style={{
                              transform:
                                "translateY(100%) translateX(-56%) translateX(38px) rotate(-63deg)",
                            }}
                          >
                            Popular
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="bg-[#ece9e9]">
                        <div className="text-5xl font-bold text-[#1259ae] text-center py-10 relative">
                          <div className=" relative w-max mx-auto">
                            <div>
                              {formation.is_promotion
                                ? parseFloat(
                                    formation?.price_formation -
                                      (formation?.price_formation *
                                        formation.promotion_formation) /
                                        100
                                  ).toFixed(2)
                                : parseFloat(
                                    formation?.price_formation
                                  ).toFixed(2)}
                              $
                            </div>
                            <div className=" absolute text-sm top-0 -right-10">
                              only
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center  md:w-[73%] w-[90%]  mx-auto  border-b-2 pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            12 Modules - Completed Online
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            11 Videos - Including First Aid
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            10 Written Clinical Case Studies
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            Theory Exam Online - Open Book
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            Practical Hijama Training Workshop*
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  border-b-2 mx-auto pb-3 mt-5">
                          <svg
                            aria-hidden="true"
                            class="size-4 mx-1 text-[#1259ae]"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="fill-current"
                              d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
                            ></path>
                          </svg>
                          <div className="mx-1">
                            Clinical Exam - At our Hijama Clinic
                          </div>
                        </div>
                        <div className="flex flex-row items-center md:w-[73%] w-[90%]  mx-auto pb-6 mt-5">
                          <div className="mx-1">
                            * Attendance required at our Hijama Clinic
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="bg-[#1259ae] py-5 px-3 ">
                        <div className="text-center">
                          <a
                            href={"/course-page/" + formation.id}
                            className=" rounded-full text-white border border-white px-5 mb-3 py-2 font-bold w-max mx-auto"
                          >
                            Buy Now
                          </a>
                          <div className="text-white text-xs text-center my-3">
                            Enroll Today and Gain Immediate Access to Your
                            Course
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="text-center mt-10 font-bold ">
          Popular Upon receiving your application, you will receive login
          details & can access you online course immediately.
        </div>
        <div className="text-center mt-4 font-bold ">
          If paying by Direct Bank Transfer we will confirm your placement
          within 2 working days.
        </div>
      </div>
      {/* slider */}

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default PricingScreen;

import axios from "../../axios";
import {
  FORMATION_LIST_REQUEST,
  FORMATION_LIST_SUCCESS,
  FORMATION_LIST_FAIL,
  //
  FORMATION_ADD_REQUEST,
  FORMATION_ADD_SUCCESS,
  FORMATION_ADD_FAIL,
  //
  FORMATION_DETAIL_REQUEST,
  FORMATION_DETAIL_SUCCESS,
  FORMATION_DETAIL_FAIL,
  //
  FORMATION_UPDATE_REQUEST,
  FORMATION_UPDATE_SUCCESS,
  FORMATION_UPDATE_FAIL,
  //
  FORMATION_DELETE_REQUEST,
  FORMATION_DELETE_SUCCESS,
  FORMATION_DELETE_FAIL,
  //
  FORMATION_LAST_REQUEST,
  FORMATION_LAST_SUCCESS,
  FORMATION_LAST_FAIL,
} from "../constants/formationConstants";

export const getLastFormation = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FORMATION_LAST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/formations/last/`, config);

    dispatch({
      type: FORMATION_LAST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORMATION_LAST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const detailFormation =
  (id, type = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FORMATION_DETAIL_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var filter = "";
      if (type !== "") {
        filter = "?filter=all";
      }
      const { data } = await axios.get(
        `/formations/detail/${id}/` + filter,
        config
      );

      dispatch({
        type: FORMATION_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FORMATION_DETAIL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { detailFormation } from "../../redux/actions/formationActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import logoMini from "../../images/icon/tassyer-logo-min.png";
import FilePreview from "./filePreviewScreen";
import { baseURLFile } from "../../constants";

function CourseOnlineScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [selectClose, setSelectClose] = useState([]);
  const [openSide, setOpenSide] = useState(true);

  const [courseSelect, setCourseSelect] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const formationDetail = useSelector((state) => state.detailFormation);
  const { loadingFormation, errorFormation, successFormation, formation } =
    formationDetail;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(detailFormation(id, "all"));
    }
  }, [navigate, userInfo, dispatch, id]);

  const handleCloseModel = (value) => {
    setSelectClose((prevState) => {
      if (prevState.includes(value)) {
        // Remove it if it exists
        return prevState.filter((item) => item !== value);
      } else {
        // Add it if it doesn't exist
        return [...prevState, value];
      }
    });
  };

  return (
    <div>
      {loadingFormation ? (
        <Loader />
      ) : errorFormation ? (
        <Alert type={"error"} message={errorFormation} />
      ) : formation ? (
        <div className="bg-white shadow-1">
          <div className="bg-[#eef1f7]">
            <div className="flex md:flex-row flex-col items-center  container mx-auto ">
              <div className="flex flex-row items-center md:justify-start justify-between md:w-max w-full">
                <Link className="block  " to="/dashboard">
                  <img
                    src={
                      "https://hijamaonlineacademy.com/wp-content/uploads/HOA-logoP1.svg"
                    }
                    className="h-14"
                    alt="Logo"
                  />
                </Link>

                <button
                  onClick={() => setOpenSide(!openSide)}
                  className="mx-2 flex flex-row items-center bg-[#4d5e6f1a] text-xs px-3 py-2 rounded"
                >
                  {!openSide ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5 mx-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5  mx-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  )}

                  <div className=" mx-1">curriculum</div>
                </button>
              </div>
              <div className="flex-1">
                <div className="text-xs ">Course:</div>
                <div className="text-sm font-bold ">
                  {formation.title_formation ?? ""}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full ">
            {openSide ? (
              <div className="md:w-1/3 w-full   bg-[#eef1f7] min-h-screen">
                <div className="px-5">
                  {formation?.curriculum_list?.map((model, index) => (
                    <div>
                      <div
                        onClick={() => {
                          handleCloseModel(model.id);
                        }}
                        className="font-medium my-4 flex flex-row items-center cursor-pointer"
                      >
                        <div className="flex-1">
                          Module {index + 1} - {model.title_module ?? ""}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className={`size-5 rounded-full p-1 bg-primary text-white mx-3 transform  transition-transform duration-500 ease-in-out ${
                            selectClose.includes(model.id)
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      </div>
                      {/* items */}
                      {model.course_list?.map((item, indexItem) => (
                        <div
                          onClick={() => {
                            setCourseSelect(item);
                            console.log(item);
                          }}
                          className={`flex flex-row bg-gray my-2 py-3 px-2 items-center rounded cursor-pointer transition-all duration-1000 ease-in-out transform ${
                            selectClose.includes(model.id) ? " hidden" : ""
                          }`}
                        >
                          <div className="text-sm">{indexItem + 1}</div>
                          <img
                            width="100"
                            height="100"
                            className="size-6 mx-3"
                            src="https://img.icons8.com/plasticine/100/file.png"
                            alt="file"
                          />
                          <div className="flex-1 flex md:flex-row flex-col">
                            <div className="flex-1 text-xs font-bold">
                              {item.title_course}
                            </div>
                            <div className="text-xs text-graydark">
                              {item.type_course === "VIDEO"
                                ? item.video_dure
                                : "Text lesson"}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="md:w-3/3 w-full min-h-screen bg-white ">
              <div className="m-5">
                {courseSelect ? (
                  <div>
                    <div className=" font-semibold my-2">
                      {courseSelect.title_course ?? ""}
                    </div>
                    <div className="my-1">Description</div>
                    <div className="my-3">
                      {courseSelect.description_course ??
                        "Not Exist any description !!"}
                    </div>
                    <div className="my-1">
                      {courseSelect.pdf_course ? (
                        <FilePreview
                          fileUrl={baseURLFile + courseSelect.pdf_course}
                        />
                      ) : null}
                    </div>
                    <div className="my-1">
                      {courseSelect.video_course ? (
                        <FilePreview
                          fileUrl={baseURLFile + courseSelect.video_course}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CourseOnlineScreen;

import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import SliderHome from "../components/SliderHome";

function SyllabusScreen() {
  return (
    <div className="text-black bg-white min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Syllabus
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto my-10">
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/2 w-full px-2 py-1">
            <div className="bg-[#1259ae] px-5 py-10  rounded-md ">
              <div className="w-full">
                <table className="w-full text-white">
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center p-2 border  border-[#80808080]"
                    >
                      Type
                    </td>
                    <td className="text-center p-2 border   border-[#80808080]">
                      Hrs
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      9 Modules / 100 units – Completed Online
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      70hrs
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      11 Videos – Including First Aid
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      1hr
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      10 Written Clinical Case Studies
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      5hrs
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full px-2 py-1">
            <div className="bg-[#1259ae] px-5 py-10 rounded-md ">
              <div className="w-full">
                <table className="w-full text-white">
                  <tr>
                    <td
                      colSpan={2}
                      className="text-center p-2 border  border-[#80808080]"
                    >
                      Type
                    </td>
                    <td className="text-center p-2 border   border-[#80808080]">
                      Hrs
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      Practical Hijama Training Workshop – At Our Clinic
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      20hrs
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      1 Theory Exam – Open Book
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      2hr
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 border  border-[#80808080]" colSpan={2}>
                      1 Clinical Practical Exam – At Our Clinic
                    </td>
                    <td className="text-center p-2 border  border-[#80808080]">
                      2hrs
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="text-center mt-10 mb-4 text-2xl">Course Contents</div>
      </div>
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default SyllabusScreen;

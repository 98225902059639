import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import SliderHome from "../components/SliderHome";

function HowToApplyScreen() {
  return (
    <div className="text-black min-h-screen bg-white">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              How to Apply
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto">
        <div className="mt-10 mb-6 text-2xl font-bold text-center">
          If you’re thinking how to apply to Hijama Online Academy or have
          questions about your application, we’ve got you covered. At HOA, we’ve
          ensured that our application is simple, stress-free, and easy to
          follow.
        </div>
        <div className="text-center text-[#1259ae] my-6 text-3xl font-bold ">
          Apply for a Certified Diploma with HOA
        </div>
        <div className="text-center text-3xl font-bold mb-5">
          Initiate Your Application
        </div>
        <div className="flex md:flex-row flex-col">
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 1</div>
              </div>
              <div className="text-center">
                Complete the application form / Make the payment for the course
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 2</div>
              </div>
              <div className="text-center">
                Receive, login details and commence your Online Learning Journey
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full px-2 py-3">
            <div
              className="border border-[#1259ae] rounded px-3 py-5"
              style={{ boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="text-center flex flex-row justify-center items-center text-[#1259ae] font-bold text-xl uppercase my-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="size-6 text-[#1259ae] mx-2"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div>Step 3</div>
              </div>
              <div className="text-center">
                Gain your Diploma qualification, & start treating patients in
                your new clinic
              </div>
            </div>
          </div>
        </div>
        <div className="text-center font-bold text-2xl my-10">
          Upon completion, we will confirm your placement within 2 working days.
        </div>
      </div>
      <div
        className="bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRkVucm9sLmpwZyZjYWNoZU1hcmtlcj0xNzEzMTE3OTQ0LTI2NzQ2JnRva2VuPTBmNThlYjgyMzE1ZmNmNzE.q.jpg')",
          backgroundPosition: "center right",
          backgroundSize: "cover",
        }}
      >
        <div className="container mx-auto py-20">
          <div className="md:w-1/2 w-full">
            <div className="text-2xl font-bold text-[#1259ae]  mb-3">
              Ready to apply for your Online Learning Certified Diploma at HOA?
            </div>
            <div className="text-sm  my-4">
              We understand that applications can often be long-winded and
              stressful, which is why we’ve made it quick and easy to apply
              directly through our website.
            </div>
            <div className=" rounded-full flex flex-row px-5 mt-3 py-2 bg-[#1259ae] text-white text-xl w-max">
              <div>Enrol Today</div>
              <svg
                aria-hidden="true"
                class="size-4 mx-1 "
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto  my-10">
        <div className="text-center text-xl font-bold my-6">
          How to apply for a certified diploma through HOA’s website?
        </div>
        <div className="text-center font-bold my-4">
          You can apply to study with us using the step-by-step process outlined
          below:
        </div>
        <ul class="list-decimal pl-5">
          <li class="text-gray-700 my-2">
            Click the ‘Enrol Today’ button on the top right-hand corner of this
            page, which can also be found on your chosen course page.
          </li>
          <li class="text-gray-700 my-2">
            Complete and submit the application form with payment. If you get
            stuck at any point, you can contact our friendly team: For UK
            blended learning and online learning courses: 07765 436 139 or
            info@hijamaonlineacademy.com For outside UK : (+44) 7765 436 139 or
            info@hijamaonlineacademy.com
          </li>
          <li class="text-gray-700 my-2">
            Once we’ve received your application and payment, we’ll send a
            confirmation email to let you know. Typically, we’ll be in touch
            within two working days to advise you on any other supporting
            documents you might need and answer any questions you might have.
            Our team will then process your application, to determine if you
            qualify for your chosen course. This can take up to 2 days,
            depending on how quickly we receive your supporting documents.
          </li>
        </ul>

        <div className="text-center  font-bold my-4">What happens next?</div>
        <div className="my-1">
          Throughout the application process, our team will be readily available
          to answer all your questions.
        </div>
        <div className="my-1">
          You will receive your login credentials. Please log in to start your
          course.
        </div>
      </div>
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default HowToApplyScreen;

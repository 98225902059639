import React from "react";
import HeaderWeb from "../layouts/HeaderWeb";
import FooterWeb from "../layouts/FooterWeb";
import SliderHome from "../components/SliderHome";

function EntryRequirementScreen() {
  return (
    <div className="text-black bg-white min-h-screen">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Entry Requirements
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto my-10 ">
        <div className="text-center font-bold text-2xl my-4">
          What's Essential for Online Learning with HOA?
        </div>
        <div className="mb-3">
          If you’re new to Online Distance Learning, joining an online course
          might seem uncertain. You might be wondering about the things you’ll
          need to purchase for your studies. At HOA, we understand these
          concerns and are here to guide you.
        </div>
        <div className="mb-3">
          The truth is, there isn’t an exhaustive list of requirements for
          studying online with HOA. Surprisingly, it’s not just about materials;
          the right attitude and discipline play a crucial role in successful
          self-paced learning.
        </div>
        <div className="mb-3">
          As a general guide, here are the three key elements we consider most
          important at HOA to make your study journey smoother and help you
          achieve your qualification.
        </div>
        <div className="mt-10">
          <div className="grid  md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            <div className="border rounded border-[#1259ae]  px-4 py-6">
              <div className="text-center text-[#1259ae] font-bold text-4xl">
                1
              </div>
              <div className="text-center text-[#1259ae] font-bold text-xl mb-5">
                English Language Requirements
              </div>
              <div className="text-center ">
                Our certified diploma course at HOA, whether face-to-face or
                online, are taught in English. If English is not your first
                language you’ll need to submit evidence that your proficiency in
                the English language meets the required level for your course,
                such as IELTS at the appropriate grade.
              </div>
              <div className="text-center">
                You can also complete an English language test with us directly
                if you wish. Our test uses the same grading system as the IELTS,
                and you’ll typically receive your results in just a couple of
                days. You can find out more by speaking to a member of our team.
              </div>
            </div>

            <div className="border rounded border-[#1259ae]  px-4 py-6">
              <div className="text-center text-[#1259ae] font-bold text-4xl">
                2
              </div>
              <div className="text-center text-[#1259ae] font-bold text-xl mb-5">
                A Device With an Internet Connection
              </div>
              <div className="text-center  ">
                Whether you own a PC, a laptop or a tablet, your online learning
                experience will be dependent on your device. Once you’re
                connected to the internet, you will have access to our study
                portal, this is your virtual classroom.
              </div>
              <div className="text-center">
                When logged in, you can access all the tools and resources you
                need to study your chosen course online. Everything you could
                possibly need to succeed in your studies can be found
                conveniently in one place.
              </div>
            </div>
            <div className="border rounded border-[#1259ae] px-4 py-6">
              <div className="text-center text-[#1259ae] font-bold text-4xl">
                3
              </div>
              <div className="text-center text-[#1259ae] font-bold text-xl mb-5">
                Self Motivation
              </div>
              <div className="text-center ">
                With no physical classes to attend, you must feel confident that
                you are able to keep on top of your workload. To do that, you
                need self-motivation and self-discipline. As with regular,
                on-campus learning, online courses have deadlines that must be
                met. Good time management plays a big role in helping you meet
                those deadlines.
              </div>
              <div className="text-center">
                Successful online learning largely relies on you being in the
                right frame of mind to digest the information you’re being
                given. If you’re planning to study at home, it’s essential that
                you’re able to adopt that mindset, despite being in an
                environment much more relaxed than a classroom.
              </div>
            </div>
          </div>
        </div>
        <div className="my-10">
          <div className="text-center text-xl">
            For any questions about your eligibility for your course, please
            feel free to contact our friendly student team.
          </div>
        </div>
      </div>
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default EntryRequirementScreen;

import React from "react";

function FooterWeb() {
  return (
    <div className="border-t-2 border-[#1259ae]  ">
      <div className="container mx-auto py-10">
        <div className="flex md:flex-row flex-col justify-between">
          <a href="/">
            <img className="  size-40" src="/assets/hijama-logo.png" />
          </a>
          <div>
            <div className="text-[#1259ae] text-2xl">Information</div>
            <div className="mt-3">
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/what-is-hijama"
                >
                  <div className="">What is Hijama?</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/authentic-narrations"
                >
                  <div>Authentic Narrations</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/faqs"
                >
                  <div>FAQ’s</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/pricing"
                >
                  <div>Pricing</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/blogs"
                >
                  <div>Blog</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="text-[#1259ae] text-2xl">Legal</div>
            <div className="mt-2">
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/gdpr"
                >
                  <div>GDPR</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/gdpr"
                >
                  <div>Privacy Policy</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/gdpr"
                >
                  <div>T&C’s</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
              <div className=" my-2">
                <a
                  className="hover:text-[#1259ae] relative group "
                  href="/contact-us"
                >
                  <div>Contact</div>
                  <div className="absolute left-0 right-0 -bottom-1 h-[2px] bg-[#1259ae] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-center"></div>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="text-[#1259ae] text-2xl">Connect</div>
            <div className="mt-2"></div>
          </div>
        </div>
      </div>
      <div className="bg-[#1259ae] py-3">
        <div className="container mx-auto">
          <div className="text-center text-white">
            Hijama Academy Usa © 2024 All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterWeb;

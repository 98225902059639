import {
  FORMATION_LIST_REQUEST,
  FORMATION_LIST_SUCCESS,
  FORMATION_LIST_FAIL,
  //
  FORMATION_ADD_REQUEST,
  FORMATION_ADD_SUCCESS,
  FORMATION_ADD_FAIL,
  //
  FORMATION_DETAIL_REQUEST,
  FORMATION_DETAIL_SUCCESS,
  FORMATION_DETAIL_FAIL,
  //
  FORMATION_UPDATE_REQUEST,
  FORMATION_UPDATE_SUCCESS,
  FORMATION_UPDATE_FAIL,
  //
  FORMATION_DELETE_REQUEST,
  FORMATION_DELETE_SUCCESS,
  FORMATION_DELETE_FAIL,
  //
  FORMATION_LAST_REQUEST,
  FORMATION_LAST_SUCCESS,
  FORMATION_LAST_FAIL,
} from "../constants/formationConstants";

export const lastFormationReducer = (state = { formation: {} }, action) => {
  switch (action.type) {
    case FORMATION_LAST_REQUEST:
      return { loadingLastFormation: true };
    case FORMATION_LAST_SUCCESS:
      return {
        loadingLastFormation: false,
        successLastFormation: true,
        formation: action.payload,
      };
    case FORMATION_LAST_FAIL:
      return {
        loadingLastFormation: false,
        successLastFormation: false,
        errorLastFormation: action.payload,
      };
    default:
      return state;
  }
};

export const detailFormationReducer = (state = { formation: {} }, action) => {
  switch (action.type) {
    case FORMATION_DETAIL_REQUEST:
      return { loadingFormation: true };
    case FORMATION_DETAIL_SUCCESS:
      return {
        loadingFormation: false,
        successFormation: true,
        formation: action.payload,
      };
    case FORMATION_DETAIL_FAIL:
      return {
        loadingFormation: false,
        successFormation: false,
        errorFormation: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import HeaderWeb from "../../layouts/HeaderWeb";
import FooterWeb from "../../layouts/FooterWeb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailFormation } from "../../redux/actions/formationActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

function CourseScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [selectPage, setSelectPage] = useState("Description");
  const [selectClose, setSelectClose] = useState([]);

  const formationDetail = useSelector((state) => state.detailFormation);
  const { loadingFormation, errorFormation, successFormation, formation } =
    formationDetail;

  useEffect(() => {
    dispatch(detailFormation(id));
  }, [dispatch, id]);

  const handleCloseModel = (value) => {
    setSelectClose((prevState) => {
      if (prevState.includes(value)) {
        // Remove it if it exists
        return prevState.filter((item) => item !== value);
      } else {
        // Add it if it doesn't exist
        return [...prevState, value];
      }
    });
  };

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div className="my-20 container mx-auto">
        {loadingFormation ? (
          <Loader />
        ) : errorFormation ? (
          <Alert type="error" message={errorFormation} />
        ) : (
          <div>
            <div className="flex md:flex-row flex-col">
              <div className="md:w-2/3 w-full px-5">
                <div className="mb-5 font-bold text-2xl ">
                  {formation?.title_formation}
                </div>
                <div className="flex flex-row my-1">
                  {formation?.teacher_formation ? (
                    <div className="flex flex-row items-center mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className=" size-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                      <div className="flex-1 mx-1">
                        <div className="text-sm">Teacher</div>
                        <div className="text-sm font-bold">
                          {formation?.teacher_formation}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {formation?.category_formation ? (
                    <div className="flex flex-row items-center mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                        />
                      </svg>

                      <div className="flex-1 mx-1">
                        <div className="text-sm">Category</div>
                        <div className="text-sm font-bold">
                          {formation?.category_formation}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="my-1">
                  <div className="flex flex-row justify-around bg-primary w-full pt-1 text-white text-xs my-5">
                    <div
                      onClick={() => {
                        setSelectPage("Description");
                      }}
                      className={`${
                        selectPage === "Description"
                          ? "bg-white text-primary"
                          : ""
                      } w-full flex-1 py-3 text-center cursor-pointer`}
                    >
                      Description
                    </div>
                    <div
                      onClick={() => {
                        setSelectPage("Curriculum");
                      }}
                      className={`${
                        selectPage === "Curriculum"
                          ? "bg-white text-primary"
                          : ""
                      } w-full flex-1 py-3 text-center cursor-pointer`}
                    >
                      Curriculum
                    </div>
                  </div>

                  {selectPage === "Description" ? (
                    <div className="">
                      <img
                        className=" mx-auto  "
                        src="https://hijamaonlineacademy.com/wp-content/uploads/HOA-logoP1.svg"
                      />
                      <div className="mt-5">
                        {formation?.description_formation ?? ""}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {formation?.curriculum_list?.map((model, index) => (
                        <div>
                          <div
                            onClick={() => {
                              handleCloseModel(model.id);
                            }}
                            className="font-medium my-4 flex flex-row items-center cursor-pointer"
                          >
                            <div>
                              Module {index + 1} - {model.title_module ?? ""}
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className={`size-5 rounded-full p-1 bg-primary text-white mx-3 transform  transition-transform duration-500 ease-in-out ${
                                selectClose.includes(model.id)
                                  ? "rotate-180"
                                  : "rotate-0"
                              }`}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 15.75 7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          </div>
                          {/* items */}
                          {model.course_list?.map((item, indexItem) => (
                            <div
                              className={`flex flex-row bg-gray my-2 py-3 px-2 items-center rounded transition-all duration-1000 ease-in-out transform ${
                                selectClose.includes(model.id) ? " hidden" : ""
                              }`}
                            >
                              <div className="text-sm">{indexItem + 1}</div>
                              <img
                                width="100"
                                height="100"
                                className="size-6 mx-3"
                                src="https://img.icons8.com/plasticine/100/file.png"
                                alt="file"
                              />
                              <div className="flex-1 flex md:flex-row flex-col">
                                <div className="flex-1 text-sm font-bold">
                                  {item.title_course}
                                </div>
                                <div className="text-sm text-graydark">
                                  {item.type_course === "VIDEO"
                                    ? item.video_dure
                                    : "Text lesson"}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="md:w-1/3  w-full px-5">
                {/* wish  */}
                <div
                  onClick={() => {
                    navigate("/card-pay/" + id);
                  }}
                  className="bg-[#1259ae] text-white flex flex-row px-4 py-5 rounded-md mt-5 items-center cursor-pointer"
                >
                  <div className="flex-1 font-medium uppercase">
                    {" "}
                    Get course{" "}
                  </div>
                  <div className=" text-right ">
                    {formation?.is_promotion ? (
                      <div>
                        <div className=" line-through text-gray opacity-50  ">
                          {parseFloat(formation?.price_formation).toFixed(2)} $
                        </div>
                        <div className="font-bold">
                          {parseFloat(
                            formation?.price_formation -
                              (formation?.price_formation *
                                formation.promotion_formation) /
                                100
                          ).toFixed(2)}{" "}
                          $
                        </div>
                      </div>
                    ) : (
                      <div className="font-bold">
                        {parseFloat(formation?.price_formation).toFixed(2)} $
                      </div>
                    )}
                  </div>
                </div>
                {/*  */}
                <div className="flex flex-row justify-between items-center py-6 border-b px-5 border-[#e1e2e4]">
                  <div>
                    Enrolled: <b> 0 students</b>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                    />
                  </svg>
                </div>
                <div className="flex flex-row justify-between items-center py-6 border-b px-5 border-[#e1e2e4]">
                  <div>
                    Duration: <b> {formation.duration_formation ?? ""}</b>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
                <div className="flex flex-row justify-between items-center py-6 border-b px-5 border-[#e1e2e4]">
                  <div>
                    Lectures: <b> 0</b>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.098 19.902a3.75 3.75 0 0 0 5.304 0l6.401-6.402M6.75 21A3.75 3.75 0 0 1 3 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 0 0 3.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008Z"
                    />
                  </svg>
                </div>
                <div className="flex flex-row justify-between items-center py-6 border-b px-5 border-[#e1e2e4]">
                  <div>
                    Video: <b> {formation.video_dure_formation}</b>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                    />
                  </svg>
                </div>
                <div className="flex flex-row justify-between items-center py-6 border-b px-5 border-[#e1e2e4]">
                  <div>
                    Level: <b> {formation.level_formation}</b>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-6 text-primary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default CourseScreen;

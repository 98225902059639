import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { detailFormation } from "../../redux/actions/formationActions";
import HeaderWeb from "../../layouts/HeaderWeb";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import FooterWeb from "../../layouts/FooterWeb";
import { baseURLFile } from "../../constants";

function CardScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const formationDetail = useSelector((state) => state.detailFormation);
  const { loadingFormation, errorFormation, successFormation, formation } =
    formationDetail;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(detailFormation(id));
  }, [dispatch, id]);

  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div className="my-20 container mx-auto">
        {loadingFormation ? (
          <Loader />
        ) : errorFormation ? (
          <Alert type="error" message={errorFormation} />
        ) : formation ? (
          <div className="my-6">
            <div className="my-3 text-xl font-bold ">Card</div>
            <hr className="text-[#eee]" />
            <div className="flex md:flex-row flex-col ">
              <div className="md:w-2/3 w-full px-5">
                <table className="w-full">
                  <thead>
                    <tr className=" uppercase">
                      <th className="py-5 text-sm text-left max-w-15">
                        Product
                      </th>
                      <th className="py-5 text-sm text-left"></th>
                      <th className="py-5 text-sm text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <td className="max-w-15">
                      <img
                        className="size-10"
                        alt="formation"
                        src={baseURLFile + formation.cover_formation}
                      />
                    </td>
                    <td>
                      <div className="text-sm">{formation.title_formation}</div>
                      <div className="text-sm">
                        1 *{" "}
                        {formation.is_promotion
                          ? parseFloat(
                              formation?.price_formation -
                                (formation?.price_formation *
                                  formation.promotion_formation) /
                                  100
                            ).toFixed(2)
                          : parseFloat(formation?.price_formation).toFixed(2)}
                        $
                      </div>
                    </td>
                    <td>
                      <div className="font-bold text-sm text-right">
                        {formation.is_promotion
                          ? parseFloat(
                              formation?.price_formation -
                                (formation?.price_formation *
                                  formation.promotion_formation) /
                                  100
                            ).toFixed(2)
                          : parseFloat(formation?.price_formation).toFixed(2)}
                        $
                      </div>
                    </td>
                  </tbody>
                </table>
              </div>
              <div className="md:w-1/3 w-full px-3">
                <div className="font-bold uppercase py-5 border-b border-[#eee] text-right">
                  Card totals
                </div>
                <div className="flex flex-row justify-between items-center py-5 border-b border-[#eee] ">
                  <div>Subtotal</div>
                  <div>
                    {formation.is_promotion
                      ? parseFloat(
                          formation?.price_formation -
                            (formation?.price_formation *
                              formation.promotion_formation) /
                              100
                        ).toFixed(2)
                      : parseFloat(formation?.price_formation).toFixed(2)}
                    $
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center py-5  font-bold">
                  <div>Total</div>
                  <div>
                    {formation.is_promotion
                      ? parseFloat(
                          formation?.price_formation -
                            (formation?.price_formation *
                              formation.promotion_formation) /
                              100
                        ).toFixed(2)
                      : parseFloat(formation?.price_formation).toFixed(2)}
                    $
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center   font-bold">
                  <button
                    onClick={() => {
                      if (userInfo) {
                        navigate("/checkout/" + id);
                      } else {
                        navigate("/login-account?checkform=" + id);
                      }
                    }}
                    className="px-6 py-3 rounded bg-primary text-white text-sm font-bold w-full"
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default CardScreen;

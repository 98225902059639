import React from "react";
import SliderHome from "../components/SliderHome";
import FooterWeb from "../layouts/FooterWeb";
import HeaderWeb from "../layouts/HeaderWeb";

function AuthenticNarrationScreen() {
  return (
    <div className="bg-white text-black min-h-screen ">
      <HeaderWeb />
      {/*  */}
      <div
        className=" bg-center bg-contain"
        style={{
          backgroundImage:
            "url('https://hijamaonlineacademy.com/wp-content/uploads/islamic-pattern-17.png')",
        }}
      >
        <div className="bg-[#4c4c4c] bg-opacity-90 w-full h-full">
          <div className="container mx-auto py-20">
            <div className="text-3xl font-bold text-center text-white">
              Authentic Narrations
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-[200px] bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            'url("https://hijamaonlineacademy.com/wp-content/plugins/phastpress/phast.php/c2VydmljZT1pbWFnZXMmc3JjPWh0dHBzJTNBJTJGJTJGaGlqYW1hb25saW5lYWNhZGVteS5jb20lMkZ3cC1jb250ZW50JTJGdXBsb2FkcyUyRkFyYWJpYy5qcGcmY2FjaGVNYXJrZXI9MTcxMzEyMzM2MC0yNTQxNDgmdG9rZW49OGMyY2E3YjJjZDk4ODRlMw.q.jpg")',
        }}
      ></div>
      <div className="my-10 container mx-auto">
        <div className="mt-10 font-bold text-2xl text-center">
          Authentic Prophetic Narrations ﷺ
        </div>
        <div className="grid  md:grid-cols-3 grid-cols-1 mt-6 gap-3">
          <div>
            Prophetic narrations ﷺ , often referred to as Hadith in Islam, are
            the recorded sayings, actions, approvals, and characteristics of
            Prophet Muhammad (peace be upon him). They serve as an essential
            source of guidance and understanding for Muslims, helping to
            interpret and implement the teachings of the Quran, the holy book of
            Islam.
          </div>
          <div>
            The importance of prophetic narrations in Islam is immense, as they
            provide insights into the practical application of Quranic
            teachings, moral guidance, legal principles (Sharia), and ethical
            conduct. Scholars of Islam meticulously scrutinize the chains of
            narrators (Isnad) and the content (Matn) of each narration to
            determine its authenticity and reliability.
          </div>
          <div>
            Muslims consider Sahih al-Bukhari and Sahih al-Muslim, along with
            other renowned Hadith collections, as the most authentic and
            reliable sources of prophetic narrations. Scholars, teachers, and
            believers regularly study these Hadiths to gain a deeper
            understanding of the principles and values of Islam and to lead a
            life in accordance with the teachings of Prophet Muhammad ﷺ.
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              Indeed the best of remedies you have is Hijama (cupping).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik, (Allah is pleased with him) - Sahih
              al-Bukhari 5371.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, that on the night of Isra (his ascension to
              the heavens) he (ﷺ) did not pass by an angel except that it was
              requested said to him, "Oh Muhammad, ask your Ummah (nation) to
              perform Hijama (cupping).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Mas'ud (Allah is pleased with him) -
              Sunan Tirmidhi 3479.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, “I did not pass by any group on the night
              when I was taken on the Night Journey (Isra’), but they said to
              me, ‘O Muhammad, ask your ummah to do Hijama (cupping).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik, (Allah is pleased with him) - Sunan
              Ibn Maajah.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "Whoever revives a Sunnah from my Sunnah and
              the people practise it, s/he will have the same reward of those
              who practise it without their reward diminishing…"
            </div>
            <div className="text-[#1259ae] my-2">Sunan ibn Maajah 209.</div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "If there was something excellent to be used
              as a remedy then it is cupping.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abu Hurairah (Allah is pleased with him) - Sunan Abi
              Dawud 3857, Sunan ibn Maajah 3476.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "I did not pass by an angel from the angels
              on the Night Journey (Isra’), except that they all requested to
              me: upon you is cupping, O Muhammad (ﷺ).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Abbas (Allah is pleased with him) - Sunan
              ibn Maajah 3477.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "Healing is in three things: in the incision
              of the cupper, in drinking honey, and in cauterizing with fire,
              but I forbid my Ummah (nation) from cauterization (branding with
              fire).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Abbas (Allah is pleased with him) - Sahih
              al-Bukhari 5681, Sahih Sunan ibn Maajah 3491.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "Indeed in Hijama (cupping) there is a cure.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Jaabir ibn Abdullah (Allah is pleased with him) -
              Sahih al-Muslim 5706.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The attendant of The Prophet (ﷺ) said, "Whenever someone would
              complain of a headache to The Prophet (ﷺ), he (ﷺ) would advise
              them to perform Hijama (cupping).
            </div>
            <div className="text-[#1259ae] my-2">
              Salma (Allah is pleased with him) - Sunan Abi Dawud 3858.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) was treated with Hijama (cupping) three times on
              the two blessed veins at the side of the blessed neck and the base
              of the blessed neck.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik (Allah is pleased with him) - Sunan
              Abi Dawud 3860, Ibn Maajah 3483.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "Whoever wants to perform Hijama (cupping)
              then let him look for 17th, 19th and 21st.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik (Allah is pleased with him) - Sunan
              Ibn Majah 3486.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said: "If anyone has himself cupped on the 17th,
              19th and 21st it will be a remedy for every disease.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated Abu Hurairah (Allah is pleased with him) - Sunan Abi
              Dawud 3861.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) had Hijama performed on his blessed head.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Abbas (Allah is pleased with him) - Sahih
              al-Bukhari 5699.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) used to cup his blessed head and would call it
              (the place at the top of the head) "Umm Mugheeth.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn 'Umar (Allah is pleased with him) -
              Sahihah 753, Sahih al-Jaami' 4804.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) had Hijama (cupping) on his blessed hip.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn 'Umar (Allah is pleased with him) - Sunan
              Abi Dawud 3863.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) had Hijama (cupping) whilst in Ihraam, on the top
              of his blessed foot.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik (Allah is pleased with him) - Sunan
              Abi Dawud 1836.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, “Hijama (cupping) on an empty stomach is
              best. In it is a cure and a blessing. It improves the intellect
              and the memory.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn 'Umar (Allah is pleased with him) - Sunan
              Ibn Maajah 3487.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, “If there is any good in your medical
              treatments, it is in the knife of the cupper, drinking honey, or
              cauterization with fire, as appropriate to the cause of the
              illness, but I would not like to be cauterized.
            </div>
            <div className="text-[#1259ae] my-2">
              According to a hadith narrated by Jaabir (Allah is pleased with
              him) - Reported by al-Bukhaari, 10/139.
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) had cupping by Abu Taibah. He commanded that he
              should be given two measures of food & talked with the members of
              his family and they lightened the burden of Kharaj (tax) from him
              (i.e they reduced his taxes. He (ﷺ) said, “The best treatment you
              can use is Hijama (cupping).
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik (Allah is pleased with him) - Sahih
              al-Bukhari 10/126; Muslim, 1577.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, “The best treatment is Hijama (cupping), it
              removes blood, lightens the back and sharpens the eyesight.
            </div>
            <div className="text-[#1259ae] my-2">
              Al–Haakim 4/212, Sunan Tirmidhi 3053.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) said, "When the weather becomes extremely hot,
              seek aid in Hijama (cupping). Do not allow your blood to rage
              (boil) such that it kills you.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Anas ibn Maalik (Allah is pleased with him) - Al-Hakim
              in his 'Mustadrak' and he authenticated it and Imam ad-Dhahabi
              agreed (4/212).
            </div>
          </div>
          {/*  */}
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) was cupped on his head while he was in Ihraam.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Abbas (Allah is pleased with him) - Sahih
              al-Bukhari 5701.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              The Prophet (ﷺ) was cupped while he was fasting.
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Abdullah ibn Abbas (Allah is pleased with him) - Sahih
              al-Bukhari 5694.
            </div>
          </div>
          <div className=" shadow-3 hover:shadow-5 p-4 rounded border border-[#d5d8dc] ">
            <img
              width="48"
              height="48"
              className=" rotate-180 text-[#1259ae]"
              src="https://img.icons8.com/fluency/48/quote-right.png"
              alt="quote-right"
            />
            <div className="my-2">
              Anas bin Malik was asked whether they disliked the cupping for a
              fasting person. He replied in the negative and said, "Only if it
              causes weakness."
            </div>
            <div className="text-[#1259ae] my-2">
              Narrated by Thabit Al-Bunani - Sahih al-Bukhari 1940.
            </div>
          </div>
        </div>
      </div>
      {/* slider */}
      <SliderHome />

      {/* footer */}
      <FooterWeb />
    </div>
  );
}

export default AuthenticNarrationScreen;

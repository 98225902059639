import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getListOrders } from "../../../redux/actions/orderActions";
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import { baseURLFile } from "../../../constants";

function OrdersScreen() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listOrders = useSelector((state) => state.orderList);
  const { orders, loadingOrders, errorOrders, pages } = listOrders;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListOrders("0"));
    }
  }, [navigate, userInfo, dispatch]);

  return (
    <div>
      {loadingOrders ? (
        <Loader />
      ) : errorOrders ? (
        <Alert type={"error"} message={errorOrders} />
      ) : orders ? (
        <div>
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-2/3 w-full px-5 mx-auto">
              <table className="w-full">
                <thead>
                  <tr className=" uppercase">
                    <th className="py-5 text-sm text-left max-w-15">Orders</th>
                    <th className="py-5 text-sm text-left"></th>
                    <th className="py-5 text-sm text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, item) => (
                    <tr className="hover:bg-bodydark1 cursor-pointer rounded-md ">
                      <td className="max-w-15 p-2 ">
                        <a href={`/order-received/${order.id}`}>
                          <img
                            className="size-12 my-2"
                            alt="formation"
                            src={baseURLFile + order.formation?.cover_formation}
                          />
                        </a>
                      </td>
                      <td colSpan={2} className="p-2">
                        <a href={`/order-received/${order.id}`}>
                          <div className="text-sm font-semibold">
                            #{order.id.slice(0, 18)}...
                          </div>
                          <div className="text-sm my-1">
                            {order.formation?.title_formation}
                          </div>
                          <div
                            className={`text-xs ${
                              order.is_paid
                                ? "text-white w-max px-4 rounded py-1 bg-warning"
                                : "text-white w-max px-4 rounded py-1 bg-danger"
                            }`}
                          >
                            {order.is_paid
                              ? "Paid at : " + order.paid_at
                              : "Not Paid"}
                          </div>
                          <hr className="text-black text-opacity-40 mt-2 " />
                        </a>
                      </td>
                      <td className="p-2">
                        <a href={`/order-received/${order.id}`}>
                          <div className="font-bold text-sm text-right">
                            {order.formation?.is_promotion
                              ? parseFloat(
                                  order.formation?.price_formation -
                                    (order.formation?.price_formation *
                                      order.formation?.promotion_formation) /
                                      100
                                ).toFixed(2)
                              : parseFloat(
                                  order.formation?.price_formation
                                ).toFixed(2)}
                            $
                          </div>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default OrdersScreen;
